table.customTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #030305;
  border-style: solid;
  color: #000000;
}

table.customTable td,
table.customTable th {
  font-weight: normal;
  border-width: 2px;
  font-size: 20px;
  border-color: #030305;
  border-style: solid;
  padding: 5px;
  text-align: left;
  width: 100px;
}

table.customTable thead {
  background-color: #f8f8f8;
}

table.hullInfoTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 2px;
  border-color: #030305;
  border-style: solid;
  color: #000000;
}

table.hullInfoTable td,
table.hullInfoTable th {
  border-width: 2px;
  border-color: #030305;
  border-style: solid;
  padding: 5px;
}

table.hullInfoTable thead {
  background-color: #f8f8f8;
}

div.space {
  margin-top: 2%;
}
